import React from 'react'
import { useClasses } from '../lib/ReactUtils'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import HERO from '../images/404/404.svg'
import { Link } from 'gatsby'

// markup
export default function NotFoundPage() {
  const root = useClasses()
  return (
    <Layout short>
      <SEO title='404'/>
      <main className='main' ref={root}>

        <div className="heading-separator"/>
        <h1 className="title">Oops ...</h1>
        <h2 className="subtitle">Sorry can't find what you are looking for</h2>
        <p className="description">
          It may have moved to the ether, decided to quit, or simply vanished. Whatever the reason .. we are really sorry.
        </p>
        <p className="description">
          Why don't you visit our <Link to='/blog'>Blog</Link> instead of staring at an empty page?
        </p>
        
        <img src={HERO} className='mt-12 mb-20 mx-auto w-full sm:w-3/5 md:w-3/7' alt='404'/>

      </main>
    </Layout>
  )
}
